exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-jsx": () => import("./../../../src/pages/join-us.jsx" /* webpackChunkName: "component---src-pages-join-us-jsx" */),
  "component---src-pages-nominate-a-patient-jsx": () => import("./../../../src/pages/nominate-a-patient.jsx" /* webpackChunkName: "component---src-pages-nominate-a-patient-jsx" */),
  "component---src-pages-our-partners-jsx": () => import("./../../../src/pages/our-partners.jsx" /* webpackChunkName: "component---src-pages-our-partners-jsx" */),
  "component---src-pages-patient-stories-jsx": () => import("./../../../src/pages/patient-stories.jsx" /* webpackChunkName: "component---src-pages-patient-stories-jsx" */)
}

